//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import DropDownLink from "../common/DropDownLink.vue";
import LangSwitch from "@/components/navigation/the-lang-switch.vue";
import LangSwitch2 from "@/components/navigation/the-lang-switch2.vue";
export default {
  components: {
    LangSwitch2,
    DropDownLink,
    LangSwitch,
  },
  name: "SiteHeader",
  data() {
    return {
      right: null,
    };
  },
  computed: {
    ...mapGetters({
      getLogo: "getLogo",
      getHeaderPhone: "getHeaderPhone",
      getHeaderMenu: "getHeaderMenu",
    }),
  },
  methods: {
    generateLink(url) {
      if (this.$route.name !== "home") {
        // Если не находимся на странице 'home', добавляем 'home' в атрибут :to
        return { name: "home",params: {locale: this.$i18n.locale} , hash: url };
      } else {
        // Если находимся на странице 'home', добавляем только hash
        return { hash: url };
      }
    },
    scrollToAnchor(anchor) {
      // Получаем элемент по его id
      console.log(anchor);
      const element = document.getElementById(anchor.slice(1));
      // Если элемент найден, осуществляем плавный скролл до него
      if (element) {
        window.scrollTo({
          behavior: "smooth",
          top: element.getBoundingClientRect().top,
        });
        history.pushState(null, "", `${anchor}`);
      }
    },
  },
  created() {},
  mounted() {},
};
