//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "accordion-table",
  methods: {
    slToggle($event) {
      this.slideToggle($event.target);
    },
  },
  mounted() {
    this.propsData.list.sort(function (a, b) {
      var dateA = a.date.split(" ")[0];
      var dateB = b.date.split(" ")[0];
      if (dateA.includes(".")) {
        dateA = dateA.split(".")[1];
      }
      if (dateB.includes(".")) {
        dateB = dateB.split(".")[1];
      }
      return dateB - dateA;
    });
  },
};
