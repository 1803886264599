//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import formText from "./formText.vue";
import formInput from "./formInput.vue";
import formTitle from "./formTitle.vue";
import FormEditor from "./formEditor.vue";
import FormCheckbox from "./formCheckbox.vue";
import FormSelect from "./formSelect.vue";
export default {
  components: {
    formInput,
    formText,
    formTitle,
    FormEditor,
    FormCheckbox,
    FormSelect,
  },
  name: "modal-form",
  data() {
    return {
      InputValues: {},
      showError: false,
      success: false,
      successInfo: null
    };
  },
  computed: {
    ...mapGetters([
      "getLogo",
      "getMenuData",
      "getFormId",
      "getThemeConfig",
      "getBackground",
      "getFormBtn",
      "getLogo",
    ]),
  },
  validations() {
    const temp = {};
    temp.InputValues = {};
    this.getMenuData.forEach((el) => {
      if (el.show_in_message === "1" && el.visibility == "1") {
        temp.InputValues[el.name] = {};
      }
      this.getMenuData.forEach((el) => {
        if (el.rules && el.visibility == "1") {
          let rules = {};
          if (el.rules.required) {
            rules = { ...rules, ...{ required } };
          }
          if (el.rules.email) {
            rules = { ...rules, ...{ email } };
          }
          if (el.rules.min) {
            rules = { ...rules, ...{ minLength: minLength(el.rules.min) } };
          }
          if (el.rules.max) {
            rules = { ...rules, ...{ maxLength: maxLength(el.rules.max) } };
          }
          temp.InputValues[el.name] = {
            ...rules,
          };
        }
      });
    });

    return temp;
  },
  methods: {
    ...mapActions(["setMenuState"]),
    closeOutside(event) {
      if (event.target.classList.contains("modal")) {
        this.close();
      }
    },
    closeSelect(event) {
      if (!event.target.classList.contains("selected") && this.$refs['form-select']) {
        this.$refs['form-select'][0].open = false
      }
    },
    changeChekbox(name, data) {
      this[name] = data;
    },
    close() {
      this.setMenuState(false);
    },
    sendForm() {
      const option = Object.assign(this.InputValues, {
        form_id: this.getFormId,
      });
      console.log(option);
      this.showError = true;
      if (!this.$v.$invalid) {
        this.axios.post("/api/request/send", option).then((res) => {
          this.success = true;
          this.successInfo = {
            success_text: res.data.data.success_text,
            success_title: res.data.data.success_title
          }
        });
      }
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    this.getMenuData.forEach((el) => {
      if (el.show_in_message === "1" && el.visibility == "1") {
        this.$set(this.InputValues, el.name, "");
      }
    });
  },
  beforeDestroy(){
    document.body.style.overflow = ''
  }
};
