//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selected: null,
      open: false,
    };
  },
  watch: {
    selected(n, o) {
      if (n === o) return;
      this.$emit("input", this.selected);
    },
  },
  props: {
    messages: {
      type: Object,
      default: () => {},
    },
    inputData: {
      type: Object,
      default: () => {},
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setOpt(opt) {
      this.selected = opt;
      this.open = false;
    },
  },
  computed: {
    ...mapGetters(["getBackground"]),
    errorText() {
      if (!this.inputData.email) {
        return this.messages.email;
      }
      if (!this.inputData.required) {
        return this.messages.required;
      }
      if (!this.inputData.min) {
        return this.messages.min;
      }
      if (!this.inputData.max) {
        return this.messages.max;
      }
      return null;
    },
  },
};
