//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SiteHeader from "../components/navigation/the-header.vue";
import SiteFooter from "../components/navigation/the-footer.vue";
import BurgerMenu from "../components/navigation/BurgerMenu.vue";
import Cookie from "js-cookie";
// import CanvasBlur from "../components/CanvasBlur.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SiteHeader,
    SiteFooter,
    BurgerMenu,
    // CanvasBlur,
  },
  data() {
    return {
      burgerShown: false,
      cookieShown: false,
    };
  },
  computed: {
    ...mapGetters(["getThemeConfig"]),
    routeKey() {
      switch (this.$route.name) {
        case "events":
          return "events";
        default:
          return this.$route.path;
      }
    },
  },
  watch: {
    $route() {
      this.burgerShown = false;
    },
  },
  methods: {
    ...mapActions(["setCurrentSize"]),
    onCookieAccept() {
      Cookie.set("cookieAccept", true);
      this.cookieShown = false;
    },
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.setCurrentSize(window)
    })
    this.setCurrentSize(window)
    const {
      data: { data: d1 },
    } = await this.axios.post("/api/settings/all", {
      lang: this.locale,
    });
    this.$store.commit("SETOPTIONS", d1);
    const {
      data: { data: d2 },
    } = await this.axios.post("/api/menu/get-by-ids", {
      lang: this.locale,
      ids: [1, 21],
    });
    this.$store.commit("SETHEADERMENU", d2.items[1]);
    this.$store.commit("SETFOOTERMENU", d2.items[21]);

    if (this.getThemeConfig) {
      const root = document.querySelector("html");
      const color =
        this.getThemeConfig.theme_bg_type === "gradient"
          ? this.getThemeConfig.theme_gradient2
          : this.getThemeConfig.theme_color;
      root.style.setProperty("--btnColor", color);
    }
  },
};
