//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StyledLink from "../common/StyledLink.vue";
export default {
    components: { StyledLink },
    name: "BlogNews",
};
