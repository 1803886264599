//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import noiseImage from "@/assets/images/noise.png";
import AccordionSection from "@/components/sections/AccordionSection.vue";
import Advantages from "@/components/sections/Advantages.vue";
import FirstScreen from "@/components/sections/FirstScreen.vue";
import ImageAndText from "@/components/sections/ImageAndText.vue";
import ImageSection from "@/components/sections/ImageSection.vue";
import ImageSlider from "@/components/sections/ImageSlider.vue";
import ImagesThree from "@/components/sections/ImagesThree.vue";
import NumbersSection from "@/components/sections/NumbersSection.vue";
import QuoteSection from "@/components/sections/quoteSection.vue";
import SimpleText from "@/components/sections/SimpleText.vue";
import Stages from "@/components/sections/Stages.vue";
import ColText from "@/components/sections/ColText.vue";
import VideoText from "@/components/sections/VideoText.vue";
import Contacts from "@/components/sections/Contacts.vue";
import Ticker from "@/components/sections/Ticker.vue";
import CardsLanding from "@/components/sections/CardsLanding.vue";
import TextNextColBtn from "@/components/sections/textNextColBtn.vue";
import QuoteSliderSection from "@/components/sections/quoteSliderSection.vue";
import TableComponent from "@/components/sections/TableComponent.vue";
// import FormWidget from "@/components/formWidget/FormWidget.vue";
import { mapGetters } from "vuex";
// import FirstScreenLanding from '@/components/sections/FirstScreen-landing.vue';
import SeeAlso from "@/components/articleSections/SeeAlso.vue";
import Cta from "@/components/sections/Cta.vue";
import FirstScreenNew from "../sections/FirstScreen-new.vue";
import LinkList from "../sections/Link-list.vue";
import TextDivider from "../sections/Text-divider.vue";
import Theses from "../sections/Theses.vue";
import FormAdapter from "../formWidget/formAdapter.vue";
import CardsSlider from "../sections/CardsSlider.vue";
import ContactsWidget from "../sections/ContactsWidget.vue";
import PartnersBlock from "../sections/PartnersBlock.vue";
import SimpleTitle from "../sections/SimpleTitle.vue";
import ContactsTabs from "../sections/ContactsTabs.vue";
import Professionals from "../sections/Professionals.vue";
import Team from "../sections/Team.vue";
import AccordionTable from '../sections/AccordionTable.vue';
import BlogNews from "../sections/BlogNews.vue";
export default {
  name: "sectionComponent",
  props: {
    main_screen: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noiseImage: noiseImage,
      checkFScreen: null,
    };
  },
  mounted() {
    const isOnPageRoute = this.$route.name === "page";
    const hasNoFirstScreenComponent = !this.propsData.some(
      (el) => el.component === "first-screen"
    );

    if (isOnPageRoute && hasNoFirstScreenComponent) {
      this.checkFScreen = `<h1>${this.main_screen.title}</h1>`;
    } else {
      this.checkFScreen = ""; // или что-то другое, если условие не выполнено
    }
  },
  components: {
    SimpleText,
    "text-n-columns": ColText,
    ImageAndText,
    "video-and-text": VideoText,
    "images-3": ImagesThree,
    Stages,
    quotes: QuoteSection,
    "full-image": ImageSection,
    Advantages,
    accordion: AccordionSection,
    numbers: NumbersSection,
    gallery: ImageSlider,
    ticker: Ticker,
    contactsComponent: Contacts,
    FirstScreen,
    blocks: CardsLanding,
    "simple-text-btn-left": TextNextColBtn,
    "quote-slider": QuoteSliderSection,
    table_component: TableComponent,
    // form_component: FormWidget,
    // FirstScreenLanding,
    SeeAlso,
    cta: Cta,
    FirstScreenLanding: FirstScreenNew,
    LinkList,
    TextDivider,
    Theses,
    form_component: FormAdapter,
    "blocks-slider": CardsSlider,
    contacts: ContactsWidget,
    partners: PartnersBlock,
    SimpleTitle,
    "contacts-tabs": ContactsTabs,
    "blocks-links": Professionals,
    Team,
    AccordionTable,
    BlogNews
  },
  computed: {
    ...mapGetters(["getMenuState", "getThemeConfig"]),
  },
  methods: {
    blockBackground(type, bg) {
      if (type === "transparent") {
        return `background: transparent`;
      } else if (type === "simple") {
        if (this.getThemeConfig.theme_noise === "1") {
          return {
            "--noiseBg": `url(${this.noiseImage})`,
            background: `${bg}`,
          };
        } else {
          return {
            background: `${bg}`,
          };
        }
      } else {
        if (type) {
          if (this.getThemeConfig.theme_noise === "1") {
            return {
              "--noiseBg": `url(${this.noiseImage})`,
              "background-color": "white",
            };
          } else {
            return {
              "background-color": "white",
            };
          }
        }
      }
    },
    sectionContainer(name) {
      if (
        name === "full-image" ||
        name === "ticker" ||
        name === "text-divider" ||
        name === "first-screen" ||
        name === "simple-text"
      ) {
        return "container-fluid";
      } else {
        return "container";
      }
    },
  },
};
