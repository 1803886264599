import { render, staticRenderFns } from "./SectionComponent.vue?vue&type=template&id=5450beea&scoped=true&"
import script from "./SectionComponent.vue?vue&type=script&lang=js&"
export * from "./SectionComponent.vue?vue&type=script&lang=js&"
import style0 from "./SectionComponent.vue?vue&type=style&index=0&id=5450beea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5450beea",
  null
  
)

export default component.exports